@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@font-face {
  font-family: "Prompt-Regular";
  src: url(/static/media/Prompt-Regular.16b4ce72.ttf);
}

@font-face {
  font-family: "Prompt-Light";
  src: url(/static/media/Prompt-Light.2aed5273.ttf);
}

@font-face {
  font-family: "Prompt-Bold";
  src: url(/static/media/Prompt-Bold.befa1f27.ttf);
}

.regular-th {
  font-family: "Prompt-Regular", "Prompt-Regular";
}

.light-th {
  font-family: "Prompt-Light", "Prompt-Light";
}

.bold-th {
  font-family: "Prompt-Bold", "Prompt-Bold";
}

/*product card in product page*/
.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1);
}

.button-like-a {
  background: none!important;
  border: none;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}

.button-like-a:hover{
  background: none!important;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}

@media print{
  .page-header{
    /* position: fixed; */
    top:2;
    margin-left: 20mm;
    margin-right: 20mm;
    background-color: #ffffff !important;
  }
  .page-footer{
    position: absolute;
    bottom:0;
    margin-left: 20mm;
    margin-right: 20mm;
    background-color: #ffffff !important;
  }
  .page-content{
    /* position: flex; */
    position:absolute;

    background-color: #ffffff !important;
    margin-bottom: auto;
    margin-left: 20mm;
    margin-right: 20mm;
    page-break-inside:avoid;      
    /* display: inherit; */
    display:inline-block;
    page-break-after :inherit !important;
  }
  
 
  .shipping-print {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
    background-color: #ffffff !important;
  }
  .print-disable {
    display: none;
  }
  .img-print {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }

  .table-color {
    background-color: #EAFAF1 !important;
    -webkit-print-color-adjust: exact; 
  }
  
  .header, .header-space,
  .footer, .footer-space {
  height: 50px;
}
  /* .img-print {
    max-width: 70%;
  } */
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
  background-color: #ffffff !important;
}


@page {
  margin: 0.25cm;
  background-color: #ffffff !important;
}










.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

body {
  font-family: "Poppins", sans-serif;
  background: #f5f5f5ff;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #7386d5;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #6d7fcc;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  color: #7386d5;
  background: #fff;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 5px;
  margin-left: 0;
  min-height: 100vh;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: scroll;
    font-size: 3vw !important;
  }
  b,t {
    font-size: 3vw;
  }

  /* .content.is-open {
    margin-left: 100%;
  } */

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  /* li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  } */
}

div.side-bar {
    height: calc(150vh - 50px);
    overflow-y: hidden;
    overflow-x: hidden;
}
