@font-face {
  font-family: "Prompt-Regular";
  src: url("../font/Prompt-Regular.ttf");
}

@font-face {
  font-family: "Prompt-Light";
  src: url("../font/Prompt-Light.ttf");
}

@font-face {
  font-family: "Prompt-Bold";
  src: url("../font/Prompt-Bold.ttf");
}

.regular-th {
  font-family: "Prompt-Regular", "Prompt-Regular";
}

.light-th {
  font-family: "Prompt-Light", "Prompt-Light";
}

.bold-th {
  font-family: "Prompt-Bold", "Prompt-Bold";
}

/*product card in product page*/
.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1);
}

.button-like-a {
  background: none!important;
  border: none;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}

.button-like-a:hover{
  background: none!important;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}

@media print{
  .page-header{
    /* position: fixed; */
    top:2;
    margin-left: 20mm;
    margin-right: 20mm;
    background-color: #ffffff !important;
  }
  .page-footer{
    position: absolute;
    bottom:0;
    margin-left: 20mm;
    margin-right: 20mm;
    background-color: #ffffff !important;
  }
  .page-content{
    /* position: flex; */
    position:absolute;

    background-color: #ffffff !important;
    margin-bottom: auto;
    margin-left: 20mm;
    margin-right: 20mm;
    page-break-inside:avoid;      
    /* display: inherit; */
    display:inline-block;
    page-break-after :inherit !important;
  }
  
 
  .shipping-print {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
    background-color: #ffffff !important;
  }
  .print-disable {
    display: none;
  }
  .img-print {
    break-inside: avoid;
  }

  .table-color {
    background-color: #EAFAF1 !important;
    -webkit-print-color-adjust: exact; 
  }
  
  .header, .header-space,
  .footer, .footer-space {
  height: 50px;
}
  /* .img-print {
    max-width: 70%;
  } */
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
  background-color: #ffffff !important;
}


@page {
  margin: 0.25cm;
  background-color: #ffffff !important;
}









